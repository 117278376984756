import { ExpertiseSectionMock } from '~/types/mock'

const AutomotiveDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'Research',
    description:
      'We delve into industry trends, user behaviors, and emerging technologies, ensuring that our solutions meet your current requirements and stand the test of time.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'UI/UX design',
    description:
      'Our experts conduct user experience (UX) testing, incorporating feedback to refine the interface, ensuring it enhances the overall usability for a seamless user journey.',
  },
  {
    icon: 'sprite3DIcon_13',
    title: 'Custom automotive software development',
    description:
      'Tailoring software for your automotive needs is about anticipating future demands. Our development team is dedicated to creating scalable and flexible solutions.',
  },
  {
    icon: 'sprite3DIcon_5',
    title: 'Mobile apps for automotive',
    description:
      'Codica ensures that your automotive software seamlessly transitions to mobile platforms, providing a consistent and accessible experience for users on various devices.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'Quality assurance',
    description:
      'Our testing ensures that every piece of software we develop meets the highest standards. Your online journey with us is smooth, secure, and reliable.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Post-launch support',
    description:
      'We actively seek opportunities for enhancements, updates, and optimizations to ensure your software continues to perform at its best in the ever-changing automotive landscape.',
  },
]

export default AutomotiveDevServices
