import { ExpertiseSectionMock } from '~/types/mock'

const AutomotiveWhyHire: ExpertiseSectionMock[] = [
  {
    title: 'Transparent communication and timely delivery',
    description:
      'Communication is vital to a successful partnership. Codica keeps you in the loop at every stage of development, ensuring transparency and clarity. We value your time and strive to deliver projects on schedule without compromising quality.',
  },
  {
    title: 'Expertise in automotive software development',
    description:
      'With a team of experienced developers, we specialize in automotive software. Whether you need a user-friendly interface for your customers or robust backend systems to manage your operations, Codica has the experience to deliver top-notch solutions.',
  },
  {
    title: 'Tailored solutions for your auto business',
    description:
      'At Codica, we understand that every automotive business is unique. Our team takes the time to understand your specific needs and crafts customized solutions that align perfectly with your goals. From sleek design to seamless functionality, we’ve got you covered.',
  },
  {
    title: 'Continuous support and maintenance',
    description:
      'Launching your website is just the beginning. Codica provides ongoing support and maintenance to ensure your automotive software solutions run smoothly. Our team is ready to address any issues promptly and implement updates to keep your site at its best.',
  },
]

export default AutomotiveWhyHire
