import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const AutomotiveDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Product discovery',
    descr: (
      <>
        In the,
        <Link
          to="/services/product-discovery/"
          className="ml5"
          key="keyTitleProductDiscovery"
        >
          product discovery
        </Link>
        , phase, we dig deep to uncover your needs, challenges, and goals,
        setting the roadmap for a successful project.
      </>
    ),
  },
  {
    num: '02',
    title: 'Design',
    descr: (
      <>
        Our,
        <Link to="/services/ui-ux-design/" className="ml5" key="keyTitleDesign">
          UI/UX design services
        </Link>
        , bring your vision to life, creating user-friendly interfaces and
        layouts that make your automotive software stand out.
      </>
    ),
  },
  {
    num: '03',
    title: 'Development',
    descr:
      'As for the development phase, our experts use cutting-edge technology to ensure your software is not just good but exceptional.',
  },
  {
    num: '04',
    title: 'Quality assurance',
    descr: (
      <>
        Our,
        <Link
          to="/services/quality-assurance/"
          className="ml5"
          key="keyTitleQA"
        >
          quality assurance services
        </Link>
        , guarantee that every bit of your software runs smoothly, as we are
        dedicated to delivering a flawless product.
      </>
    ),
  },
  {
    num: '05',
    title: 'Support',
    descr:
      'We stand by your side, ready to tackle any issues, provide updates, and ensure your automotive software solutions continue to shine brightly.',
  },
]

export default AutomotiveDevProcess
