import { ExpertiseSectionMock } from '~/types/mock'

const AutomotiveDevIndustries: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'For startups',
    description:
      'Whether you are just starting or dreaming of a big project, our services are tailored to give your automotive experience the digital boost it needs. We help turn your startup vision into an online reality.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'For small and medium businesses',
    description:
      'We understand the unique needs of small and medium-sized businesses. Our services are designed to fit your scale, providing effective and affordable solutions to enhance your online presence in the automotive industry.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'For enterprises',
    description:
      'Codica’s automotive software development services cater to enterprises, offering sophisticated digital solutions that align with your corporate goals. Trust us to take your industry growth to the next level, no matter how large your enterprise is.',
  },
]

export default AutomotiveDevIndustries
