import { ExpertiseSectionMock } from '~/types/mock'

const AutomotiveDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_61',
    title: 'Customization and personalization',
    titleWidth: 'maxWidth_240',
    description:
      'We tailor-make solutions just for you. Our focus is on personalizing your digital space to match your unique needs, ensuring a one-of-a-kind online experience.',
  },
  {
    icon: 'spriteIcon_4',
    title: 'Real-time data insights',
    titleWidth: 'maxWidth_240',
    description:
      'Our team provides insights that empower your decisions, offering a deeper understanding of your platform performance, user behavior, and market trends.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Cost savings and efficiency',
    titleWidth: 'maxWidth_240',
    description:
      'Codica is all about efficiency. Our solutions automate processes, optimize operations, and cut costs, helping your business run smoother and more innovatively.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Adaptability to industry trends',
    titleWidth: 'maxWidth_240',
    description:
      'At Codica, our exciting automotive solutions are designed to quickly adapt to the latest industry tendencies, keeping your digital presence in tune with the times.',
  },
  {
    icon: 'spriteIcon_50',
    title: 'Competitive edge',
    titleWidth: 'maxWidth_240',
    description:
      'Our automotive solutions are developed to enhance your offerings, satisfaction, and overall market standing, ensuring you stay ahead of the industry competition.',
  },
  {
    icon: 'spriteIcon_23',
    title: 'Improved user interface (UI)',
    titleWidth: 'maxWidth_240',
    description:
      'We enhance the joy of automotive software development with user-friendly interfaces that bring entertainment, navigation, and personalized settings to your fingertips.',
  },
]

export default AutomotiveDevBenefits
