import { FAQ } from '~/types/data-array'

const AutomotiveDevFAQ: FAQ[] = [
  {
    question:
      'How can automotive software development services drive innovation in my business?',
    answer:
      'These services boost innovation by adding advanced features to your project. It is about smart connections, analytics, and excellent interfaces. These advancements make your products stand out and allow for continuous updates, keeping your offerings fresh and up-to-date in a fast-paced market.',
  },
  {
    question:
      'Can automotive software development services integrate with existing systems in my automotive business?',
    answer:
      'Absolutely. Our automotive software development solutions are designed with integration in mind. We customize the latest software to work seamlessly with your existing setup. Our specialists aim to make your systems work better today and in the future.',
  },
  {
    question:
      'What security measures are implemented in automotive software development services?',
    answer:
      'This involves encryption for data protection during transmission, secure coding to prevent vulnerabilities, and regular security audits for threat identification. We implement user authentication and authorization mechanisms, ensuring only authorized personnel can interact with sensitive data.',
  },
]

export default AutomotiveDevFAQ
