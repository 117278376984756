// extracted by mini-css-extract-plugin
export var automotiveBenefitsSection = "T_h1";
export var automotiveCasesSection = "T_h5";
export var automotiveClientQuotesSection = "T_h9";
export var automotiveDevExpertiseSection = "T_h7";
export var automotiveIndustriesSection = "T_h0";
export var automotiveProcessSection = "T_h2";
export var automotiveRequirementsSection = "T_h4";
export var automotiveServicesSection = "T_hZ";
export var automotiveSkilledSection = "T_h8";
export var automotiveTechStackSection = "T_h3";
export var automotiveWhyHireSection = "T_h6";