import { ExpertiseSectionMock } from '~/types/mock'

const AutomotiveDevRequirements: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_67',
    title: 'Deep industry expertise',
    description:
      'We bring in-depth knowledge of the automotive industry to the table. Understanding the nuances of your business helps us tailor solutions that fit seamlessly into your world.',
  },
  {
    icon: 'spriteIcon_1',
    title: 'Tech excellence',
    description:
      'Our commitment to tech excellence is non-negotiable. We use the most remarkable technologies to build robust and innovative software that keeps you ahead in the digital race.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'User-centric design',
    description:
      'Your users matter, and so does their experience. Our design revolves around them, creating interfaces that are easy to use and a joy to navigate, ensuring a positive user journey.',
  },
  {
    icon: 'spriteIcon_17',
    title: 'Scalability',
    description:
      'At Codica, our solutions are scalable and ready to grow with your business. Our software expands with you as you expand, accommodating increased data and features effortlessly.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Security measures',
    description:
      'Our top priority is securing your data and software. We implement robust security measures to protect against threats, ensuring a secure environment for your automotive software.',
  },
  {
    icon: 'spriteIcon_3',
    title: 'Interoperability',
    description:
      'At Codica, our solutions are designed to seamlessly work with various automotive systems and third-party apps, creating an interconnected and cohesive automotive ecosystem.',
  },
]

export default AutomotiveDevRequirements
